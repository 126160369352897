<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl Detail IMEI-SV 
                template(v-if="status_detail === 'loading'") ...
                template(v-else) {{detail.imei_sv?detail.imei_sv:'-'}}
            .header-cta    
                router-link(to="/device-track").btn-default
                    IconSet(type="chevron-left")
                    span.m-hide.ml-3 Back

        .card--table__body
            .flex.items-center.mb-2.justify-between

                .flex.items-center.flex-grow.gap-3
                    
                .block.mr-6
                    .flex.flex-wrap.items-center.justify-between
                        .field.mr-3.relative
                        JsonCSV(
                            :data="csvData"
                            :name="csvName"
                            class="btn btn-primary"
                        ) Download XLS        
            .box-table.box-table--target
                table.table
                    thead.m-hide
                        tr
                            th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                    tbody
                        tr(v-if="status_detail_list === 'loading'")
                            td.text-center(:colspan="tableThead.length")
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")
                        
                        template(v-else-if="detail_list && detail_list.data.length > 0")
                            tr(v-for="item in detail_list.data")
                                td {{ item.requested_at?item.requested_at:'-' }}
                                td {{ item.user?item.user:'-' }}
                                td {{ item.msisdn?item.msisdn:'-' }}
                                td {{ item.provider?item.provider:'-' }}
                                td {{ item.datetime?item.datetime:'-' }}
                                td {{ item.imei?item.imei:'-' }}
                                td {{item.device_vendor || item.device_name ? item.device_vendor + ' ' +item.device_name:'-'}}
                                //- td(colspan="2")
                                    template(
                                        v-if="item.target && item.target.length > 0"
                                    )
                                        .flex.justify-between(v-for="data in item.target.slice(0,4)")
                                            button.link.flex.items-center.text-sm.text-left(
                                                @click="detailTarget(data)"
                                                :class="{'mb-2':item.target.length > 1}"
                                            )
                                                .mr-2
                                                    figure.w-8.h-8.rounded-full.overflow-hidden
                                                        img.w-full.h-full(:src="data.photos && data.photos[0] ? data.photos[0].file : getDefaultImage" @error="getDefaultImage")
                                                span {{ data.name }}
                                            span.mr-4 {{ toString(data.cases) }}
                                        template(v-if="item.target.length > 4")
                                            button.link(@click="getTarget(true, item.target)") More
                                    template(v-else) -
                                td
                                    template(
                                        v-if="item.target && item.target.length > 0"
                                    )
                                        button.link.flex.items-center.text-sm.text-left(
                                            @click="detailTarget(data)"
                                            v-for="data in item.target.slice(0,4)"
                                            :class="{'mb-2':item.target.length > 1}"
                                        )
                                            .mr-2
                                                figure.w-8.h-8.rounded-full.overflow-hidden
                                                    img.w-full.h-full(:src="data.photos && data.photos[0] ? data.photos[0].file : getDefaultImage" @error="getDefaultImage")
                                            span {{ data.name }}
                                        template(v-if="item.target.length > 4")
                                            button.link(@click="getTarget(true, item.target)") More
                                    template(v-else) -
                                td
                                    template(
                                        v-if="item.target && item.target.length > 0"
                                    )
                                        .flex(
                                            v-for="data, idx in item.target"
                                        ) 
                                            span(:class="{'mt-5':idx > 0}") {{ toString(data.cases) }}
                                    template(v-else) -
                        tr(v-else)
                            td.text-center(:colspan="tableThead.length") No Data Available
    
    .popup-layer(
        v-bind:class="{'is-show' : openTarget}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl Targets
                button.btn-icon-40(
                    @click="getTarget(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-4
                    //- .small-heading Target Lists
                    .mb-4
                        button.link.flex.items-center.text-sm.text-left(
                            v-for="data in targetPopUp"
                            @click="detailTarget(data)"
                            :class="{'mb-2':targetPopUp.length > 1}"
                        )
                            .mr-2
                                figure.w-8.h-8.rounded-full.overflow-hidden
                                    img.w-full.h-full(:src="data.photos && data.photos[0] ? data.photos[0].file : getDefaultImage" @error="getDefaultImage")
                            span {{ data.name }}
</template>

<script>
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import Spinner from '@/components/Spinner.vue';
import { encrypt } from '@/util/crypto';
import JsonCSV from 'vue-json-csv';

export default {
    name: 'pageImeiSvDetail',
    components: {
        IconSet,
        DateRangePicker,
        Loading,
        NoDataAvailable,            
        Spinner,    
        JsonCSV,        
    },

    data() {
        return {
            tableThead: [
                {
                    name: 'Requested At',
                },{
                    name: 'IMSI',
                },{
                    name: 'MSISDN',
                },{
                    name: 'Provider Name',
                },{
                    name: 'Last Seen in This Device',
                },{
                    name: 'Latest known IMEI',
                },{
                    name: 'Latest known Device Type',
                },{
                    name: 'Known Target',
                },{
                    name: 'Known Case',
                },
            ],
            csvName: 'device_track_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.xls',
            csvData: [], 
            defaultImage: this.$store.getters['config/getDefaultImageProfile'],
            targetPopUp: [],
            openTarget: false,

        }
    },
    computed: {
        ...mapState('imeiSv', [
            'status_detail',
            'detail',
            'status_detail_list',
            'detail_list',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        source() {
            return this.$route.params.source;
        },
    },
    methods: {
        async getData() {
            // this.reset(true);

            // EventEmit.$emit('showLoading', true);

            await this.$store.dispatch('imeiSv/getDetail', this.id)
            .then((resp) => {
                if (resp.result === 'success') {
                    // EventEmit.$emit('showLoading', false);
                    this.$store.dispatch('imeiSv/getDetailList', [resp.data.imei_sv, this.source]);

                } else {
                    EventEmit.$emit('error');
                }
            });
        },
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        detailTarget(data) {
            // console.log('click')
            var msisdn = this.checkMsisdn(data)
            if (msisdn.length > 0) {
                this.$router.push({ name: 'pageTargetDetailHome', params: { id: data.id ,key: encrypt(msisdn[0].value + '&&msisdn') }  })
            } else {
                let clues = data.clues
                this.$router.push({ name: 'pageTargetDetailHome', params: { id: data.id ,key: encrypt(clues[0].value + '&&'+clues[0].name) }  })
                // EventEmit.$emit('error', 'Target does not have a MSISDN clue')
            }
        },
        checkMsisdn(data) {
            var msisdn = data.clues.filter(function (clue) {
                return clue.name === 'msisdn'
            })
            return msisdn
        },
        getTarget(open=true, targets=[]) {
            if(open){
                this.targetPopUp = targets
                this.openTarget = true
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.targetPopUp = []
                this.openTarget = false
                EventEmit.$emit('overflowHidden', false);
            }
        },
        getDataCsv(data) {
            this.csvData = data.map((item) => {
                return {
                    Requested_At            : item.requested_at?item.requested_at:'-',
                    imsi                    : item.user?item.user:'-',
                    msisdn                  : item.msisdn?item.msisdn:'-',
                    Provider_Name	        : item.provider?item.provider:'-',
                    Last_Seen_in_This_Device: item.datetime?item.datetime:'-',
                    Latest_known_IMEI	    : item.imei?item.imei:'-',
                    Latest_known_Device_Type: item.device_name?item.device_name:'-',
                    // Known_Target		    : item.target[0].name?item.target[0].name:'-',
                    // Known_Case	            : item.target[0].cases[0].operation_name?item.target[0].cases[0].operation_name:'-',
                };
            }) 
        },
    },

    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    watch: {
        route_name(value) {
            this.getData();
        },
        detail_list(newData){
            if (this.detail_list && this.detail_list.data && this.detail_list.data.length > 0) {
                console.log("=====data personal",this.detail_list.data)
                this.getDataCsv(this.detail_list.data)
            }
        },
        detail(newData) {
            // if (newData && newData.imei_sv) {                
            //     this.$store.dispatch('imeiSv/getDetailList', [newData.imei_sv, this.source]);
            // }
        }
    },
    async beforeCreate() {
        await this.$store.commit('imeiSv/setStatusDetailList', 'loading');
        await this.$store.commit('imeiSv/setDetailList', {});
    },
    async created() {
        this.getData();
    },
}
</script>


<style lang="sass">
.btn-close-search
    @apply absolute top-0 right-0 flex items-center justify-center
    width: 46px
    height: 46px

.box-search-result
    background-color: $color-secondary-600
    border: 1px solid lighten($color-secondary-500, 10%)
    border-top: 0
    ul
        max-height: 180px
        overflow: auto
    button
        @apply block py-3 px-5
        &:hover
            color: $color-main-500

</style>